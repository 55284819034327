<template>
	<div class="content reda">
		<p class="readP" v-for="item in fictionTxt">{{item}}</p>
		<el-dialog
		  :visible.sync="warningBl"
		  width="90%"
		  center
		  top="27vh"
		  :show-close=false
		  :close-on-click-modal=false>
		   <h3 style="text-align: center;padding: 25px;font-size: 1.2rem;">开通<span style="color: #f14668">VIP</span>观看全站</h3>
		   <span slot="footer" class="dialog-footer">
			   <el-button type="danger" @click="readBack" round><i class="el-icon-orange"></i> 我再看看</el-button>
			   <el-button type="warning" @click="toRecharge" round><i class="el-icon-unlock"></i> 开通VIP</el-button>
		  </span>
		</el-dialog>
		<div v-show="fullscreenLoading" class="container">
		 <div class="loading"></div>
		</div>
	</div>
</template>

<script>
export default{
	data() {
	  return {
		  userId:"",
		  fictionId:"",
		  fictionTxt:[],
		  loading:"",
		  warningBl:false,
		  fullscreenLoading: false,
		  money:'9',
		  moneyStr:'',
	  }
	},
	created(){
		this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
		if(this.userId==null || this.userId==""){
			if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
				this.userId = this.$parent.userId;
			}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
				this.userId = this.$route.query.userId;
			}
		}
		this.fictionId = this.$route.query.fictionId;
		this.addNumAxios();
	},
	/* beforeRouteEnter(to, from, next) {
	    next(vm => {
	      if (from.name == 'Bought') {
	        vm.warningBl = false;
	        return;
	      } else {
	        vm.warningBl = false;
	        return;
	      }
	    })
	}, */
	methods: {
		toRecharge(){
			this.$router.push({
			　　path: '/Recharge2',
			    query: {
					userId:this.userId
				}
			});
		},
		toMallInfo(){
			this.$router.push({
			　　path: '/My', 
			    query: {
					userId:this.userId
				}
			});
		},
		readBack(){
			this.$router.go(-1);
		},
		 txtAxios(){
		 	var that = this;
			that.loading = this.$loading({
			  lock: true,
			  text: '拼命加载中...',
			  spinner: 'el-icon-loading',
			  background: 'rgba(0, 0, 0, 0.7)'
			});
			var params = new URLSearchParams();
			params.append('id', that.fictionId);
			this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/fiction/fictionGet',params)
		 	  .then(function (response) {
				 that.loading.close();
				 if(response.data.code==1){
					that.money = response.data.data.money;
					that.moneyStr = that.money+"元本部";
					var txtStr = response.data.data.data;
					if(txtStr.indexOf("span")>=0){
						txtStr=txtStr.replaceAll('<span','');
						txtStr=txtStr.replaceAll('font-size: 16px;','');
						txtStr=txtStr.replaceAll('style=','');
						txtStr = txtStr.replaceAll(/\"/g, "");
					}
					that.fictionTxt = txtStr.split("。");
				 }else{
					that.moneyStr = that.money+"元本部";
				 }
		 	  })
		 	  .catch(function (error) {
				that.moneyStr = that.money+"元本部";
				that.loading.close();
		 		console.log(error);
		 	  });
		 },
		 addNumAxios(){
		 	var that = this;
		 	that.loading = this.$loading({
		 	  lock: true,
		 	  text: '拼命加载中...',
		 	  spinner: 'el-icon-loading',
		 	  background: 'rgba(0, 0, 0, 0.7)'
		 	});
		 	var params = new URLSearchParams();
		 	params.append('id', this.userId);
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/addNm',params)
		 	  .then(function (response) {
		 		  if(response.data.code==1){
		 			 var datas = response.data.data;
		 			 that.warningBl = true;
		 		  }else{
		 			  if(response.data.code==50003){
		 				  var datas = response.data.data;
		 				  if(datas.totalNum==1600000){
							    that.warningBl = false;
		 				  }else if(datas.totalNum==16000001){
							  that.warningBl = false;
						  }else{
		 					  that.warningBl = true;
		 					  that.loading.close();
		 				  }
		 			  }else{
		 				  that.warningBl = true;
		 				  that.loading.close();
		 			  }
		 		  }
		 		  that.txtAxios();
		 	  })
		 	  .catch(function (error) {
		 		  that.loading.close();
		 		  console.log(error);
		 	  });
		 },
	}
}
</script>

<style>
	.readP{
		line-height: 26px;
		text-indent:2em;
	}
	.reda .el-button.is-round{
		padding: 12px 20px;;
	}
	.reda .el-dialog__footer{
		padding: 5px 20px 40px;
	}
</style>

<template>
	<div class="content popular">
		<div class="hidden-sm-and-up">
			<el-input @change="addNumAxios" :placeholder="seachrPlar" v-model="$parent.input1" class="input-with-select">
			 <el-button @click="addNumAxios" slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div>
		 <el-row :gutter="10" style="margin: 20px auto;" class="tags-box">
		   <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2" v-for="(item, index) in searchData" :key="index" :offset="index > 0 ? 0 : 0">
		 	<div class="grid-content" @click="toSearchPopular(item.name)">
		 	<el-button type="danger" size="mini">{{item.name}}</el-button>
		   </div></el-col>
		 </el-row>
	</div>
</template>

<script>
	export default{
		data() {
		  return {
			userId:'',
			seachrPlar:'国产，高清，少妇，黑丝',
			searchData:[],
		  }
		},
		created() {
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
			if(this.GLOBAL.bottomData=="" || this.GLOBAL.bottomData==null){
				this.searchAxios();
			}else{
				this.searchData = this.GLOBAL.bottomData;
			}
	    },
		methods: {
			searchAxios(){
				var that = this;
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoType/searchListData')
				  .then(function (response) {
					  var datas = response.data.data;
					  if(response.data.code==1){
						 that.searchData = datas;
						 that.GLOBAL.bottomData = datas;
					  }
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			toSearchPopular(name){
				this.$parent.input1 = name;
				this.addNumAxios();
			},
			addNumAxios(){
				var that = this;
				if(this.$parent.input1=="" || this.$parent.input1==null){
					this.$parent.open("通知","请输入关键字！");
					return;
				}
				const loading = this.$loading({
				  lock: true,
				  text: '正在搜索中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				that.searchSaveAxios();
				var params = new URLSearchParams();
				params.append('id', this.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/addNm',params)
				  .then(function (response) {
					  loading.close();
					  if(response.data.code==1){
						 that.$parent.centerDialogVisibleVip = true;
					  }else{
						  if(response.data.code==50003){
							  var datas = response.data.data;
							  if(datas.totalNum==1600000){
								  if(datas.isVip=='30'){
									that.$parent.seachrTitle = "包月以上套餐用户（不包括日体验卡）专属功能";
									that.$parent.seachrButtonText = '立即升级';
								  	that.$parent.centerDialogVisibleVip = true;
								  }else{
									that.$parent.centerDialogVisibleVip = false;
									that.toSearch();
								  }
							  }else if(datas.totalNum==16000001){
								  if(datas.isVip=='30'){
									that.$parent.seachrTitle = "包月以上套餐用户（不包括日体验卡）专属功能";
									that.$parent.seachrButtonText = '立即升级';
									that.$parent.centerDialogVisibleVip = true;
								  }else{
									that.$parent.centerDialogVisibleVip = false;
									that.toSearch();
								  }
							  }else{
								  that.$parent.centerDialogVisibleVip = true;
							  }
						  }else{
							 that.$parent.centerDialogVisibleVip = true;
						  }
					  }
				  })
				  .catch(function (error) {
					  loading.close();
					  that.$parent.centerDialogVisibleVip = true;
					  console.log(error);
				  });
			},
			toSearch(){
				if(this.$parent.input1=="" || this.$parent.input1==null){
					this.$parent.open("通知","请输入搜索关键字！");
				}else{
					this.$router.push({
					　　path: '/Search', 
						 query: { 
							input1: this.$parent.input1,
							userId: this.userId,
						}
					})
				}
			},
			searchSaveAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('name', this.$parent.input1);
				this.axios.post(this.GLOBAL.serverSrcSs+'mobile/tantan/videoType/save',params)
				  .then(function (response) {
					  var datas = response.data.data;
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
		}
	}
</script>

<style>
	.popular .hidden-sm-and-up{
		margin-top: 16px;
	}
	.popular .hidden-sm-and-up input{
		height: 45px;
		font-size: 1rem;
	}
</style>

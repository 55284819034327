<template>
	<div class="content soundDiv">
		<!-- <el-tabs type="border-card" stretch @tab-click="tabsevn">
			<el-tab-pane label="短篇有声小说"> -->
				<div v-if="duanpData.length>0">
					<p class="soundpbt">短篇有声小说</p>
					<div>
						<ul class="duanList">
						  <li v-for="item in duanpData" class="list-item">
							  <span class="duanListSpan" @click="toMp3Play(item)">
								<i class="el-icon-headset"></i>
								{{item.name}}
								<!-- <span style="float: right;color: #F56C6C;">{{item.money}}元</span> -->
							  </span>
						  </li>
						   <li v-if="duanpData.length<dpCount" class="soundjzgd" @click="duanpAxios(++dpPageNo,10,'30')">点击加载更多</li>
						</ul>
					</div>
				</div>
			<!-- </el-tab-pane>
			<el-tab-pane label="长篇有声小说"> -->
				<div v-if="soundData.length>0">
					<p class="soundpbt">长篇有声小说</p>
					<el-row :gutter="10" style="margin: 0 auto;" class="vod-box-row-sound">
						<el-col class="videoCol" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in soundData" :key="index" :offset="index > 0 ? 0 : 0">
							<el-card :body-style="{ padding: '0px'}">
							<div style="position: relative;" @click="toSoundList(item)">
								 <el-image class="image" fit="fill" :src="item.imgUrl" lazy>
									<div slot="placeholder" class="image-slot">
									  加载中<span class="dot">...</span>
									</div>
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								  </el-image>
							  </div>
							</el-card>
							<p @click="toSoundList(item)" class="names">{{item.title}}</p>
						  </el-col>
					</el-row>
					<div v-if="soundData.length<cpCount" class="soundjzgdDiv" @click="soundAxios(++cpPageNo,10)">点击加载更多</div>
				</div>
			<!-- </el-tab-pane>
		</el-tabs> -->
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from '../components/footer.vue'
export default{
	components:{
		Footer
	},
	data() {
	  return {
		  userId:"",
		  soundData:[],
		  duanpData:[],
		  tjpData:[],
		  mp3Data:"",
		  dpPageNo:1,
		  dpPageSize:10,
		  cpPageNo:1,
		  loading:"",
		  warningMp3:false,
		  warningMp3Tj:false,
		  wxDataMp3:"",
		  zfbDataMp3:"",
		  zfbDataMp3Tj:"",
		  wxDataMp3Tj:"",
		  zfDomain:"",
		  cpCount:0,
		  dpCount:0,
		  qjloding:"",
		  vloading:true,
		  tabIndex:0
	  }
	},
	created(){
		this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
		if(this.userId==null || this.userId==""){
			if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
				this.userId = this.$parent.userId;
			}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
				this.userId = this.$route.query.userId;
			}
		}
		this.duanpAxios(this.dpPageNo,this.dpPageSize,"30");
		this.soundAxios(1,10);
	},
	methods: {
		toSoundList(item){
			this.$router.push({
				name: "Mp3",
				query: {
					userId:this.userId,
					soundId:item.id
				}
			});
		 },
		 toMp3Play(item){
			this.$router.push({
				name: "Mp3Play",
				query: {
					userId:this.userId,
					soundId:0,
					mp3Id:item.id
				}
			});
		 },
		 soundAxios(pageNo,pageSize){
		 	var that = this;
			const loading = this.$loading({
			  lock: true,
			  text: '拼命加载中...',
			  spinner: 'el-icon-loading',
			  background: 'rgba(0, 0, 0, 0.7)'
			});
			var params = new URLSearchParams();
			params.append('pageNo', pageNo);
			params.append('pageSize', pageSize);
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/yousheng/list',params)
		 	  .then(function (response) {
				  loading.close();
		 		  var datas = response.data.data;
		 		  if(response.data.code==1){
					that.cpCount = datas.count;
					if(that.soundData.length>0){
						for(var i in datas.data){
							that.soundData.push(datas.data[i]);
						}
					}else{
						that.soundData = datas.data;
					}
		 		  }else{
					that.soundOpen("暂无更多数据，正在录制中");
				  }
		 	  })
		 	  .catch(function (error) {
				loading.close();
		 		console.log(error);
		 	  });
		 },
		 duanpAxios(pageNo,pageSize,remarks){
		 	var that = this;
			if(pageNo>1){
				that.loading = this.$loading({
				  lock: true,
				  text: '拼命加载中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
			}
			var params = new URLSearchParams();
			params.append('pageNo', pageNo);
			params.append('pageSize', pageSize);
			params.append('type', '0');
			params.append('remarks', remarks);
			params.append('status', '0');
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/yousheng/mp3lList',params)
		 	  .then(function (response) {
				  if(pageNo>1){
					that.loading.close();
				  }
		 		  var datas = response.data.data;
		 		  if(response.data.code==1){
					if(remarks=='1'){
						that.tjpData = datas.data;
					}else{
						that.dpCount = datas.count;
						if(that.duanpData.length>0){
							for(var i in datas.data){
								that.duanpData.push(datas.data[i]);
							}
						}else{
							that.duanpData = datas.data;
						}
					}
		 		  }else{
					that.soundOpen("暂无更多数据，正在录制中");
				  }
				  that.vloading=false;
		 	  })
		 	  .catch(function (error) {
				if(pageNo>1){
				  that.loading.close();
				}
		 		console.log(error);
		 	  });
		 },
		 soundOpen(content) {
		 	this.$message({
		 	  message: content,
		 	  type: 'error',
		 	  offset:200
		 	});
		 },
	}
}
</script>

<style>
	.soundDiv .el-tabs--border-card>.el-tabs__content {
		padding: 0px;
	    padding-top: 10px;
		background: #F1F3F4;
	}
	.duanList{
	   list-style-type: none;
	   margin: 0px;
	   padding: 0px;
	}
	.duanList .list-item{
	   height: 50px;
	   background: #FFFFFF;
	   margin-top: 10px;
	   line-height: 50px;
	   padding-left: 5px;
	   cursor: pointer;
	   position: relative;
	}
	.duanList .duanListSpan{
		width: 90%;
	    position: absolute;
	}
	.soundpbt{
		font-size: 1rem;
	    font-weight: bold;
	    margin-top: 11px;
		color: #f14668;
	}
	.soundjzgd,.soundjzgdDiv{
		text-align: center;
	    margin-top: 12px;
	}
	.soundjzgdDiv{
		margin-bottom: 15px;
	}
	.vod-box-row-sound .el-col{
		padding: 10px 0px;
		cursor: pointer;
	}
	.vod-box-row-sound .sound_word{
		width: 70%;
		position: absolute;
		left: 30%;
		top: 0px;
	}
	.vod-box-row-sound .name{
	  font-size: 1rem;
	  color: #2f2f2f;
	  font-weight: bold;
	  padding: 10px;
	}
	.vod-box-row-sound .summary{
	  color: #8b8b8b;
	  font-size: 0.9rem;
	  padding: 0px 10px;
	  line-height: 1.5rem;
	}
	.vod-box-row-sound .image {
		 width: 100%;
		 height: 13rem;
		 display: block;
	}
	.vod-box-row-sound .names{
		box-sizing: border-box;
		height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		word-break: break-word;
		line-height: 20px;
		margin-top: 3px;
		margin-bottom: 3px;
		text-align: center;
	}
	.ygm{
		color: #f14668;
		font-weight: bold;
		font-size: 1rem;
	}
</style>

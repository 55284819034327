<template>
	<div class="content mp3Div">
		<el-row :gutter="0" style="margin: 0 auto;" class="vod-box-row-mp3">
			<el-col class="videoCol" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
				<el-card :body-style="{ padding: '0px' }">
				<div style="position: relative;">
					 <el-image class="image" fit="fill" :src="mp3Data.imgUrl" lazy>
						<div slot="placeholder" class="image-slot">
						  加载中<span class="dot">...</span>
						</div>
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					  </el-image>
					  <div class="sound_word">
						  <p class="name">{{mp3Data.title}}</p>
						  <!-- <p class="type">类型：{{mp3Data.type}}</p> -->
						  <p class="type">价格：{{mp3Data.money}}元</p>
						  <p class="type">播音：{{mp3Data.broadcast}}</p>
						  <p class="type">时间：{{mp3Data.mp3Date}}</p>
					  </div>
				  </div>
				</el-card>
			  </el-col>
		</el-row>
		<p class="summary">{{mp3Data.summary}}</p>
		<p class="fjst">分集收听(共{{mp3Data.num}}集)</p>
		<el-row :gutter="20">
		  <el-col v-for="index in mp3Data.num" :key="index" :span="6">
			  <div class="fjbutton" @click="toMp3Play(index)">
				  <span v-if="index<=mp3Data.freeNum">免</span>
				  {{index}}
			  </div>
			  </el-col>
		</el-row>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from '../components/footer.vue'
export default{
	components:{
		Footer
	},
	data() {
	  return {
		  userId:"",
		  mp3Data:"",
		  soundId:"",
		  warningMp3:false,
		  wxDataMp3:"",
		  zfbDataMp3:"",
		  zfDomain:"",
		  centerDialogVisibleMp3:false,
		  num:1
	  }
	},
	created(){
		this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
		if(this.userId==null || this.userId==""){
			if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
				this.userId = this.$parent.userId;
			}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
				this.userId = this.$route.query.userId;
			}
		}
		this.soundId = this.$route.query.soundId;
		this.mp3Axios();
	},
	methods: {
		 mp3Axios(){
		 	var that = this;
			var params = new URLSearchParams();
			params.append('id', that.soundId);
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/yousheng/list',params)
		 	  .then(function (response) {
		 		  var datas = response.data.data;
		 		  if(response.data.code==1){
		 			that.mp3Data = datas.data[0];
		 		  }
		 	  })
		 	  .catch(function (error) {
		 		console.log(error);
		 	  });
		 },
		 mp3Open(content) {
		 	this.$message({
		 	  message: content,
		 	  type: 'error',
		 	  offset:200
		 	});
		 },
		 toMp3Play(item){
		 	this.$router.push({
		 		name: "Mp3Play",
		 		query: {
		 			userId:this.userId,
		 			soundId:this.soundId,
		 			mp3Id:item
		 		}
		 	});
		 },
	}
}
</script>

<style>
	.vod-box-row-mp3 .el-col{
		padding: 10px 0px;
		cursor: pointer;
	}
	.vod-box-row-mp3 .sound_word{
		width: 70%;
		position: absolute;
		left: 30%;
		top: 0px;
	}
	.vod-box-row-mp3 .name{
	  font-size: 1rem;
	  color: #2f2f2f;
	  font-weight: bold;
	  padding: 10px;
	}
	.vod-box-row-mp3 .type{
	  color: #8b8b8b;
	  font-size: 0.9rem;
	  padding: 0px 10px;
	  line-height: 2rem;
	}
	.vod-box-row-mp3 .image {
		 width: 30%;
		 height: 10rem;
		 display: block;
	}
	.mp3Div .summary{
	  color: #8b8b8b;
	  font-size: 0.9rem;
	  line-height: 1.5rem;
	  text-indent:2em;
	  margin-top: 5px;
	}
	.mp3Div .ljst{
	  width: 100%;
	}
	.mp3Div .ljgm{
	  width: 100%;
	  margin-top: 10px;
	  margin-left: 0px;
	}
	.mp3Div .fjst{
		font-size: 1rem;
		font-weight: bold;
		padding: 7px 0px 0px 0px;
	}
	.fjbutton{
		border: 1px solid #ccc;
		width: 100%;
		height: 30px;
		text-align: center;
		line-height: 30px;
		color: #4472b9;
		font-size: 14px;
		position: relative;
		margin-top: 10px;
	}
	.fjbutton span{
		background: #FEA013;
		color: #fff;
		width: 20px;
		height: 20px;
		line-height: 20px;
		border-radius: 5px;
		position: absolute;
		left: 0px;
		top: 0px;
	}
	.ygm{
		color: #f14668;
		font-weight: bold;
		font-size: 1rem;
	}
</style>

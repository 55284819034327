<template>
	<!--content star -->
	<div class="content">
		<div class="contentTow">
			<div class="user-box">
				<div>
					<div class="icon">{{iconText}}</div>
					<div class="user-info">
						<p class="user-name">账号：{{name}}</p>
						<p class="user-pwd">密码：{{passWord}}</p>
						<div class="buttons">
							<el-button type="danger" @click="toModify" size="mini" plain>修改信息</el-button>
							<el-button type="danger" @click="toSwitch" size="mini" plain>切换账户</el-button>
						</div>
					</div>
				</div>
				<p class="user-info-tips">请务必<span style="color: #f14668;font-weight: bold;font-size: 1rem;"> 截图 </span>保存好账号密码，或修改你的登录信息</p>
				<p v-if="$parent.zfssIndex==1" class="user-info-tips" style="margin: 0px;">{{$parent.zfssTitlex}}<a style="font-size: 18px;" @click="toZfss" href="javascript:void(0);">点击此处</a></p>
			</div>
			<el-row id="levels">
			  <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content">
				<p class="heading">播放次数</p>
				<p v-if="isVip==1" class="title">无限制</p>
				<p v-else class="title">{{frequency}}</p>
			  </div></el-col>
			  <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content">
				  <p class="heading">总邀请</p>
				  <p class="title">{{totalNum}}</p>
			  </div></el-col>
			  <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content">
				<p class="heading">今日邀请</p>
				<p class="title">{{dayCount}}</p>
			  </div></el-col>
			</el-row>
			<div v-if="isVip==1" class="user-tips-vip">
				<h3 style="margin: 0px;">恭喜您成为VIP：到期时间</h3>
				<h4 style="color: #F56C6C;">{{userData.maturityDates}}</h4>
			</div>
			<div class="user-tips">
				<p>{{$parent.shareCotent}}{{host}}</p>
				<el-button @click="copyToClip()" type="danger" size="medium " plain>点击复制</el-button>
			</div>
			<div class="user-tips2">
				<p>提示：点击复制你的推广链接并分享到微信QQ群、抖音私信陌生人、微博贴吧博客等，有人点击访问时你的账户马上增加视频解锁5部</p>
				<p style="color: #f14668;">累计分享满10次，即可有机会永久免费观看全站资源</p>
				 <el-carousel height="30px" direction="vertical" :autoplay="true">
				    <el-carousel-item v-for="item in mnUserData" :key="item.index">
				      <h3 class="medium">{{item.num}}分钟前： {{item.name}}  获得永久观看资格</h3>
				    </el-carousel-item>
				  </el-carousel>
			</div>
			<div class="user-tips3">
				<p>邀请记录<span>（只显示最近10条）</span></p>
				<el-row class="user-tips3-head">
				  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"><div class="grid-content">
					<p>记录</p>
				  </div></el-col>
				  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"><div class="grid-content">
					  <p>时间</p>
				  </div></el-col>
				   <el-divider></el-divider>
				</el-row>
				<el-row class="yqjl" v-for="(item, index) in resultList" :key="index">
				  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"><div class="grid-content">
					<p>{{item.name}} - 播放次数 + 5</p>
				  </div></el-col>
				  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"><div class="grid-content">
					  <p>{{item.createDate}}</p>
				  </div></el-col>
				  <el-divider></el-divider>
				</el-row>
			</div>
		</div>
		<Bottom></Bottom>
	</div>
	<!--content end -->
</template>

<script>
	import Bottom from '../components/bottom.vue'
	export default{
		components:{
			Bottom
		},
		data() {
		  return {
			userId:"",
			name:"",
			passWord:"",
			frequency:0,
			num:0,
			totalNum:0,
			isVip:0,
			uid:"",
			host:"520tantan.com",
			dayCount:0,
			resultList:[],
			iconText:"1",
			userData:[],
			mnUserData:[]
		  }
		},
		created(){
			document.body.scrollTop=document.documentElement.scrollTop=0 //页面滚动到顶部
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
			this.host = "http://"+document.domain;
			this.loginAxios();
			this.resultAxios();
			for(var i=0;i<=15;i++){
				var nums = Math.floor(Math.random()*10+1);
				var names = Math.random().toString(36).slice(2,6);
				var userJson = {name:names+"***",num:nums};
				this.mnUserData.push(userJson);
			}
		},
		methods: {
			toModify(){
				this.$router.push({
					name: "Modify",
					query: {
						userId:this.userId,
						typePath:'my'
					}
				});
			},
			toSwitch(){
				this.$router.push({
					name: "Switch",
					query: {
						userId:this.userId
					}
				});
			},
			toZfss(){
				this.$router.push({
					name: "Zfss",
					query: {
						userId:this.userId
					}
				});
			},
			loginAxios(){
				var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: '拼命加载中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('id', this.userId);
				params.append('type', "1");
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/login',params)
				  .then(function (response) {
					  loading.close();
					  that.domainAxiosMy();
					  var datas = response.data.data;
					  that.userData = datas;
					  if(response.data.code==1){
						 that.userId = datas.id;
						 that.name = datas.name;
						 that.uid = datas.uid;
						 that.host = that.host+"/?uid="+that.uid;
						 that.passWord = datas.password;
						 that.frequency = datas.frequency-datas.num;
						 if(that.frequency<0){
							 that.frequency = 0;
						 }
						 that.num = datas.num;
						 that.isVip = datas.isVip;
						 that.maturityDate = datas.maturityDate;
						 localStorage.setItem("tantan_name",datas.name);
						 localStorage.setItem("tantan_userId_ds",datas.id);
						 that.iconText = datas.name.substr(0,1);
					  }else{
						  that.open('通知','网络出错，请重试！');
					  }
				  })
				  .catch(function (error) {
					loading.close();
					console.log(error);
				  });
			},
			resultAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('id', this.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/recordList',params)
				  .then(function (response) {
					  var datas = response.data.data;
					  if(response.data.code==1){
						that.dayCount = datas.count;
						that.totalNum = datas.zscount;
						that.resultList = datas.data;
					  }else{
						  //that.open('通知','网络出错，请重试！');
					  }
				  })
				  .catch(function (error) {
					console.log(error);
				  });
			},
			copyToClip() {
				var content = this.$parent.shareCotent+this.host;
				let aux = document.createElement("input");
				aux.setAttribute("value", content);
				document.body.appendChild(aux);
				aux.select();
				document.execCommand("copy");
				document.body.removeChild(aux);
				this.open("复制成功","");
			},
			domainAxiosMy(){
				var that = this;
				var params = new URLSearchParams();
				params.append('userid', that.userId);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/zhuanfa/listWzff',params)
				  .then(function (response) {
					  if(response.data.code==1){
						var datas = response.data.data;
						that.host = datas;
					  }
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			},
		}
	}
</script>

<style>
.contentTow{
	max-width: 940px;
	margin: 0 auto;
}
.user-box{
   width: 100%;
   background: white;
   border-radius: 10px;
   text-align: center;
   padding: 12px 0px;
   position: relative;
}
.user-box .icon{
   width: 70px;
   height: 70px;
   border-radius: 50%;
   font-size: 2rem;
   display: inline-block;
   background-color: #f14668;
   border-color: transparent;
   color: #fff;
   cursor: pointer;
   text-align: center;
   line-height: 70px;
}
.user-box .user-info{
	display: inline-block;
	text-align: left;
	margin-left: 20px;
	position: relative;
	top: 10px;
}
.user-box .user-info p{
	font-size: 14px;
	font-weight: bold;
	color: #4a4a4a;
}
 .user-box .user-info .buttons{
	margin-top: 5px;
 }
.user-box .user-info .buttons .el-button{
	 border: 2px solid #F56C6C;
	 background: white;
	 font-weight: 700;
}
.user-box .user-info .buttons .el-button:hover{
	background-color: #F56C6C;
}
.user-tips .el-button{
	border: 2px solid #F56C6C;
	background: white;
	font-weight: 700;
}
.user-box .user-info-tips{
	font-size: .75rem;
	color: #4a4a4a;
	font-weight: bold;
	margin-top: 1em;
}
#levels{
	width: 100%;
	background: white;
	border-radius: 10px;
	text-align: center;
	padding: 10px 0px;
	margin-top: 10px;
}
#levels .grid-content .heading{
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 1px;
	margin-bottom: 10px;
}
#levels .grid-content .title{
	color: #363636;
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.125;
}
.user-tips,.user-tips2,.user-tips-vip{
	width: 100%;
	background: white;
	border-radius: 10px;
	text-align: center;
	padding: 10px 0px;
	margin-top: 10px;
}
.user-tips p{
	font-size: 14px;
	font-weight: 700;
	color: #4a4a4a;
}
.user-tips .el-button{
	margin-top: 1em;
}
.user-tips2 p{
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	color: #4a4a4a;
	line-height: 1.7em;
}
.user-tips3{
	background: white;
	border-radius: 10px;
	padding: 10px 5px;
	margin-top: 10px;
	margin-bottom: 20px;
}
.user-tips3>p{
	font-size: 14px;
	color: #4a4a4a;
	line-height: 1.5;
	font-weight: 700;
}
.user-tips3>p>span{
	font-size: .75rem;
	font-weight: inherit;
}
.user-tips3-head{
	color: #363636;
	font-size: .75rem;
	font-weight: 700;
	margin-top: 15px;
}
.user-tips3-head p{
	padding: .25em .5em;
}
.el-divider--horizontal{
	margin: 24px 0px 10px 0px;
}
.yqjl p{
	color: #7a7a7a;
	padding: .25em .5em;
	font-size: .75rem;
}
.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 30px;
	margin: 0;
  }
</style>

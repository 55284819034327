<template>
	<div class="recharge2">
		<div class="ktvip">开通<span style="color: #f14668;font-weight: 700;">VIP</span>观看全部视频</div>
		<div v-if="zfListData && moneyData" class="moneyRadio">
			<el-row :gutter="1" style="margin: 0px auto;padding: 0 3px;">
				<el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" style="margin-top: 8px;" v-for="(item, index) in moneyData" :key="index" :offset="index > 0 ? 0 : 0">
					<div class="moneyridiv" @click="moneyChange(item)">
						<div class="rmoney">{{item.amt}}元</div>
						{{item.name}}
						<div v-if="(item.remarks!=''&&item.remarks!=null)" class="subscript">{{item.remarks}}</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- <a href="javascript:void(0);">(推荐使用支付宝)</a> -->
		<div class="ktvip">充值方式</div>
		<div class="czfsGroup">
			<el-row :gutter="5">
				<el-col v-if="(item.remarks!='停用' && (item.type==lllxType || item.type==3))" :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-for="(item, index) in zfListData" :key="index" :offset="index > 0 ? 0 : 0">
					<el-radio v-model="radio2" :label="item.sort" border @change="typeChange(item.address,item.name,item.cid,item.remarks)">{{item.name}}</el-radio>
				</el-col>
				<div class="wfzf"><a href="javascript:void(0);" @click="centerDialogVisibleWfzf=true">无法支付?</a></div>
			</el-row>
		</div>
		<div class="re2tille">
			<h4>无法支付解决方案:</h4>
			<div v-for="(item, index) in $parent.wftsData" :key="index" :offset="index > 0 ? 0 : 0" v-if="item.type==1">{{item.name}}</div>
			<h4 v-if="$parent.zfssIndex==1" style="margin-top: 18px;">安心购买</h4>
			<div v-if="$parent.zfssIndex==1">{{$parent.zfssTitlex}}<a style="font-size: 18px;" @click="toZfss" href="javascript:void(0);">点击此处</a></div>
			<h4 style="margin-top: 18px;">提醒您</h4>
			<div>1、账户因为切换网络有可能会生成新的账户，请记住自己充值了的账户密码，如果不能看了请切换回充值过的账户！<span style="color: red;">(账号在右上角"账户")</span></div>
			<div>2、如出现点击支付无反应的情况，请更换浏览器尝试，如出现弹框点击<span style="color: cornflowerblue;">"允许"</span></div>
		</div>
		<div v-show="fullscreenLoading" class="container">
		 <div class="loading"></div>
		</div>
		<el-dialog
		  title="无法支付时请阅读"
		  :visible.sync="centerDialogVisibleWfzf"
		  width="80%"
		  top="15vh"
		  center>
		  <div class="dialogBodyx">
			<p style="font-size: 16px;line-height: 30px;margin-bottom: 20px;" v-for="(item, index) in $parent.wftsData" :key="index" :offset="index > 0 ? 0 : 0" v-if="item.type==1">{{item.name}}</p>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data () {
		  return {
			radio1: '',
			radio2:'',
			userId:"",
			fullscreenLoading: false,
			videoId:"1015",
			money:"50",
			zfListData:"",
			zfAddress:"",
			zfName:"",
			zfDomain:"huyrov.bhxdkb.cn",
			zfCid:"",
			moneyData:"",
			centerDialogVisibleWfzf:false,
			dialogTableVisible:false,
			lllxType:3,
			isSplit:'',
			splitAmt:'',
			splitType:'',
			remarks:''
		  };
		},
		created(){
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
			var u = navigator.userAgent;
			var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
			var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if(isAndroid==true){
				this.lllxType = 1;
			}
			if(isiOS==true){
				this.lllxType = 2;
			}
			if(isAndroid==false && isiOS==false){
				this.lllxType = 3;
			}
			this.qqNumAxios();
		},
		methods: {
			moneyChange(item) {
				if(this.remarks!=null && this.remarks!='' && this.remarks!='停用' &&this.remarks.length>5){
					this.zfbOpen(this.remarks);
					return;
				}
				this.money = item.amt;
				this.isSplit = item.isSplit;
				this.splitAmt = item.splitAmt;
				this.splitType = item.splitType;
				this.rechargex(this.money);
			},
			typeChange(address,name,cid,remarks){
				this.zfAddress = address;
				this.zfName = name;
				this.zfCid = cid;
				this.remarks = remarks;
				this.moneyAxios(address);
			},
			toZfss(){
				this.$router.push({
					name: "Zfss",
					query: {
						userId:this.userId
					}
				});
			 },
			//dnf1
			rechargex(num){
				if(this.userId==null || this.userId==""){
					if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
						this.userId = this.$parent.userId;
					}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
						this.userId = this.$route.query.userId;
					}else{
						//this.userId = "111111111111";
						this.zfbOpen('获取ID失败，请更换浏览器尝试！');
						return;
					}
				}
				this.fullscreenLoading = true;
				setTimeout(() => {
				  this.fullscreenLoading = false;
				}, 5000);
				var numFixed = num;
				var dqDomain = document.domain;
				if(this.zfDomain=="" || this.zfDomain==null){
					this.zfDomain = "huyrov.bhxdkb.cn";
				}
				window.open("http://"+this.zfDomain+"/"+this.zfAddress+"/?amt="+numFixed+"&userId="+this.userId+"&qturl="+dqDomain);
			},
			zfbOpen(content) {
				this.$message({
				  message: content,
				  type: 'error',
				  offset:200
				});
			},
			qqNumAxios(){
				var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: '正在加载中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				this.axios.post(this.GLOBAL.serverSrcZf+'mobile/tantan/pay/list')
				  .then(function (response) {
					  loading.close();
					  if(response.data.code==1){
					    var datas = response.data.data;
						that.zfListData = datas;
						var datasLength = datas.length-1;
						that.zfDomain = datas[datasLength].address;
						for (var i = 0; i < datas.length; i++) {
							if(datas[i].remarks!="停用" && (datas[i].type==that.lllxType || datas[i].type==3)){
								that.zfAddress = datas[i].address;
								that.zfCid = datas[i].cid;
								that.zfName = datas[i].name;
								that.radio2 = datas[i].sort;
								that.remarks = datas[i].remarks;
								break;
							}
						}
						that.moneyAxios(that.zfAddress);
					  }else{
						that.zfbOpen("加载数据失败，请刷新页面！");
						//that.qqNumAxios();
					  }
				  })
				  .catch(function (error) {
					  loading.close();
					  that.zfbOpen("加载数据失败，请刷新页面！");
					  //that.qqNumAxios();
					  console.log(error);
				  });
			},
			moneyAxios(type){
				var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: '正在切换通道...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('type', type);
				this.axios.post(this.GLOBAL.serverSrcZf+'mobile/tantan/pay/moneyList',params)
				  .then(function (response) {
					  loading.close();
					  if(response.data.code==1){
					    var datas = response.data.data;
						that.moneyData = datas;
					  }else{
						 that.zfbOpen("暂无数据！");
					  }
				  })
				  .catch(function (error) {
					  loading.close();
					console.log(error);
				});
			},
		}
	}
</script>

<style>
	.recharge2{
		margin: 8px;
		padding-top: 1px;
		max-width: 940px;
		margin: 8px auto;
	}
	.recharge2 h4{
		margin: 0px;
	}
	.recharge2 .moneyridiv{
		width: 90%;
	    border: 2px solid #f14668;
	    border-radius: 4px;
	    padding: 12px 0px;
	    line-height: 1;
		font-size: 14px;
		display: inline-block;
		padding-left: 0px;
		color: #606266;
		font-weight: 500;
		cursor: pointer;
		overflow: hidden;
		position: relative;
	}
	.recharge2 .moneyridiv:hover{
		background-color: #f14668;
		color: #FFFFFF;
		font-size: 14px;
		font-weight: 500;
	}
	.recharge2 .moneyridiv:hover .rmoney{
		font-size: 18px;
		font-weight: bold;
		padding: 2px 0px;
		color: #FFFFFF;
	}
	.titpsre{
		text-align: center;
		margin-top: 30px;
	}
	.titpsre p{
		line-height: 29px;
		padding: 10px;
	}
	.recharge2 .el-radio.is-bordered.is-checked{
		border-color: #f14668;
	}
	.recharge2 .el-radio__input.is-checked+.el-radio__label{
		color: #f14668;
	}
	.recharge2 .el-radio__label{
		display: inline-block;
		padding-left: 0px;
		color: #606266;
		font-weight: 500;
	}
	.recharge2 .el-row{
		text-align: center;
	}
	.recharge2 .rmoney{
		font-size: 18px;
		font-weight: bold;
		padding: 2px 0px;
		color: #f14668;
	}
	.recharge2 .el-radio__input.is-checked .el-radio__inner{
		border-color: #f14668;
		background: #f14668;
		box-shadow: 0px 0px 0px #dcdfe6;
	}
	.rtitle{
		width: 100%;
		color: #fff;
		line-height: 36px;
		font-size: 18px;
		text-align: center;
		background: #F14668;
	}
	.ktvip{
		padding-left: 6px;
	    font-size: 13px;
	    margin-top: 20px;
		max-width: 940px;
	}
	.recharge2 .czfsGroup .el-radio.is-bordered{
		width: 90%;
		height: auto;
		padding: 12px 0px;
		text-align: center;
		border: 2px solid #DCDFE6;
		/* border: 1px solid #f14668; */
	}
	.recharge2 .czfsGroup .el-radio.is-bordered.is-checked{
		border-color: #f14668;
	}
	.recharge2 .czfsGroup .el-radio__inner:hover{
		border-color: #DCDFE6;
		box-shadow: 0px 0px 0px #dcdfe6;
	}
	.czfsGroup{
	    max-width: 940px;
	    margin: 2px auto;
	}
	.recharge2 .czfsGroup .el-col{
		margin-top: 10px;
	}
	.fuzhi{
		color: white;
		background: cornflowerblue;
		padding: 3px 6px;
	}
	.container {
		 position: absolute;
		 left: 50%;
		 top: 50%;
		 transform: translate(-50%, -50%);
		 z-index: 99999;
	}
	.loading {
		 width: 100px;
		 height: 100px;
		 border-radius: 100%;
		 border: 7px #ffffff solid;
		 border-right-color: #87CEEB;
		 animation: loading 1s linear infinite;
	}
	@keyframes loading {
		 0% {
		 transform: rotate(0deg);
		 }
		 100% {
		 transform: rotate(360deg);
		 }
	}
	.re2tille{
		margin-top: 35px;
		padding-bottom: 20px;
		padding-left: 6px;
		max-width: 940px;
	}
	.re2tille div{
		margin-top: 8px;
		line-height: 25px;
		font-size: 14px;
	}
	.subscript{
		color: #fff;
		width: 102px;
		position: absolute;
		left: -36px;
		text-align: center;
		line-height: 20px;
		font-family: "黑体";
		background-color: #F14668;
		top: 2px;
		-moz-transform:rotate(-40deg);
		-webkit-transform:rotate(-40deg);
		-o-transform:rotate(-40deg);
		-ms-transform:rotate(-40deg);
		transform:rotate(-40deg);
	  }
	  .wfzf{
		display: inline-block;
		margin-top: 30px;
		font-size: 12px;
	  }
	  .wfzf a{
		text-decoration: underline;
	  }
	  .re2tille .xiangqing a{
		  text-decoration: underline;
		  font-size: 14px;
		  font-weight: 100;
	  }
</style>

<template>
	<div class="content">
		<el-row :gutter="10" style="margin: 0 auto;" class="vod-box-row-fiction">
			<el-col class="videoCol" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in fictionData" :key="index" :offset="index > 0 ? 0 : 0">
				<el-card :body-style="{ padding: '0px' }">
				<div style="position: relative;" @click="toFictionList(item)">
					 <el-image class="image" fit="cover" :src="item.videoUrl" lazy>
						<div slot="placeholder" class="image-slot">
						  加载中<span class="dot">...</span>
						</div>
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					  </el-image>
				  </div>
				</el-card>
				<p class="name" @click="toFictionList(item)">{{item.name}}</p>
			  </el-col>
		</el-row>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from '../components/footer.vue'
export default{
	components:{
		Footer
	},
	data() {
	  return {
		  userId:"",
		  fictionData:[],
	  }
	},
	created(){
		this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
		if(this.userId==null || this.userId==""){
			if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
				this.userId = this.$parent.userId;
			}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
				this.userId = this.$route.query.userId;
			}
		}
		this.fictionAxios();
	},
	methods: {
		toFictionList(item){
			this.$router.push({
				name: "FictionList",
				query: {
					userId:this.userId,
					fictionId:item.id
				}
			});
		 },
		 fictionAxios(){
		 	var that = this;
		 	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/fiction/fiction')
		 	  .then(function (response) {
		 		  var datas = response.data.data;
		 		  if(response.data.code==1){
		 			that.fictionData = datas;
					for (var i = 0; i < that.fictionData.length; i++) {
						that.fictionData[i].videoUrl = "https://"+document.domain+that.fictionData[i].videoUrl;
					}
		 		  }
		 	  })
		 	  .catch(function (error) {
		 		console.log(error);
		 	  });
		 },
	}
}
</script>

<style>
	.vod-box-row-fiction .el-col{
		padding: 5px 0px;
		cursor: pointer;
	}
	.vod-box-row-fiction .name{
	  padding: 5px 0px;
	  text-align: center;
	  font-size: 1.2rem;
	  color: #363636;
	  font-weight: bold;
	}
	.vod-box-row-fiction .image {
		 width: 100%;
		 height: 11rem;
		 display: block;
	}
</style>

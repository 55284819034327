<template>
	<div class="content special">
		<el-tabs type="border-card" stretch @tab-click="tabsevn">
		  <el-tab-pane label="特价视频">
			  <div class="vod-box">
			  	<el-row :gutter="12" style="margin: 0 auto;" class="vod-box-row">
			  		<el-col  :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in videoData" :key="index" :offset="index > 0 ? 0 : 0">
			  			<el-card :body-style="{ padding: '0px' }" >
			  			<div style="position: relative;" @click="toVideo(item)">
			  				 <el-image class="image" fit="cover" :src="item.imgUrl" lazy>
			  					<div slot="placeholder" class="image-slot">
			  					  加载中<span class="dot">...</span>
			  					</div>
			  					<div slot="error" class="image-slot">
			  						加载失败...<i class="el-icon-picture-outline"></i>
			  					</div>
			  				  </el-image>
			  				  <div class="bfcs">{{item.num}}万次播放</div>
			  			  </div>
			  			  <div style="padding: 5px;" @click="toVideo(item)">
			  				<p class="name">{{item.name}}</p>
							<div class="bottom clearfix">
							  <span class="time">特价视频</span>
							</div>
			  			  </div>
			  			</el-card>
			  		  </el-col>
			  	</el-row>
			  </div>
			  <Bottom style="margin-top: 10px;"></Bottom>
		  </el-tab-pane>
		  <el-tab-pane label="特价小说">
			 <ul class="fictionList">
			    <li @click="toRead(item)" v-for="item in fictionListData" class="list-item" style="background: #F1F3F4;">
			  	  <i class="el-icon-document"></i>
			  		{{item.name}}
			    </li>
			  </ul>
			  <div class="ckgdsx">
				<el-button type="danger" @click="toFiction">更多小说 ></el-button>
			  </div>
		  </el-tab-pane>
		  <el-tab-pane label="精品推荐">
			 <div class="vod-box">
			 	<el-row :gutter="12" style="margin: 0 auto;" class="vod-box-row">
			 		<el-col  :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in jpvideoData" :key="index" :offset="index > 0 ? 0 : 0">
			 			<el-card :body-style="{ padding: '0px' }" >
			 			<div style="position: relative;" @click="toVideo(item)">
			 				 <el-image class="image" fit="cover" :src="item.imgUrl" lazy>
			 					<div slot="placeholder" class="image-slot">
			 					  加载中<span class="dot">...</span>
			 					</div>
			 					<div slot="error" class="image-slot">
			 						加载失败...<i class="el-icon-picture-outline"></i>
			 					</div>
			 				  </el-image>
			 				  <div class="bfcs">{{item.num}}万次播放</div>
			 			  </div>
			 			  <div style="padding: 5px;" @click="toVideo(item)">
			 				<p class="name">{{item.name}}</p>
							<div class="bottom clearfix">
							  <span class="time">精品视频</span>
							</div>
			 			  </div>
			 			</el-card>
			 		  </el-col>
			 	</el-row>
			 </div>
			  <Bottom style="margin-top: 10px;"></Bottom>
		  </el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import Bottom from '../components/bottom.vue'
	export default{
		components:{
			Bottom
		},
		data() {
		  return {
			userId:'',
			videoData: [],
			fictionListData:[],
			jpvideoData:[],
		  }
		},
		created() {
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
			this.tjVideoAxios();
	    },
		methods: {
			tabsevn(item){
				if(item.index==0){
					if(this.videoData.length==0){
						this.tjVideoAxios();
					}
				}else if(item.index==1){
					if(this.fictionListData.length==0){
						this.tjFictionAxios();
					}
				}else{
					if(this.jpvideoData.length==0){
						this.jpVideoAxios();
					}
				}
			},
			toFiction(){
				this.$router.push({
				　　path: '/Fiction', 
				    query: { 
						userId:this.userId
					}
				})
			},
			toRead(item){
			 this.$router.push({
				name: "Read",
				query: {
					userId:this.userId,
					fictionId:item.id
				}
			 });
			},
			toVideo(item){
				this.$router.push({
				　　path: '/Video', 
				    query: { 
						videoId: item.id,
						userId:this.userId,
						videoName:""
					}
				})
			},
			tjVideoAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('remarks', "v");
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoList/list',params)
				  .then(function (response) {
					  var datas = response.data.data.data;
					   if(response.data.code==1){
						 that.videoData = datas;
					  }
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			jpVideoAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('remarks', "g");
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/videoList/list',params)
				  .then(function (response) {
					  var datas = response.data.data.data;
					   if(response.data.code==1){
						 that.jpvideoData = datas;
					  }
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			tjFictionAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('remarks', 's');
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/fiction/fictionList',params)
				  .then(function (response) {
					  var datas = response.data.data;
					   if(response.data.code==1){
						 that.fictionListData = datas.data;
					  }
				  })
				  .catch(function (error) {
					  that.open('通知','网络出错，请重试！');
					  console.log(error);
				  });
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			}
		}
	}
</script>

<style>
	.special .el-tabs--border-card>.el-tabs__content {
		padding: 0px;
	    padding-top: 10px;
	}
	.special .vod-box-row .bottom{
		margin-top: 0px;
	}
</style>
